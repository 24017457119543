import './App.css'
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  LayersControl,
  LayerGroup,
  Circle,
  Rectangle,
  FeatureGroup,
  useMapEvents
} from 'react-leaflet'

import { mapCentre, mapBounds, startZoom, minZoom, maxZoom, historicMapAttribution, osMapAttribution } from "./config.js";
import markers from "./data/markers.js";
import {useState} from "react";

const MapListeners = () => {
  const map = useMapEvents({
    click: e => {
      console.log("Location:")
      console.log(e.latlng.lat, e.latlng.lng);
    }
  })
  return null;
}

const App = () => {
  const [baseLayer, selectBaseLayer] = useState('before');
  return (
    <>
      <MapContainer
        className="map-container"
        center={mapCentre}
        zoom={startZoom}
        scrollWheelZoom={true}
        minZoom={minZoom}
        maxZoom={maxZoom}
        maxBounds={mapBounds}
      >
        <MapListeners />
        <div className="layerButtonContainer">
          <div className="layerButton" style={{backgroundColor: baseLayer === 'before' ? '#2bd9fe' : '#ffffff'}} onClick={() => selectBaseLayer('before')}>BEFORE</div>
          <div className="layerButton" style={{backgroundColor: baseLayer === 'after' ? '#2bd9fe' : '#ffffff'}} onClick={() => selectBaseLayer('after')}>AFTER</div>
        </div>
        {
          baseLayer === 'before'
          ?
          <TileLayer
            attribution={historicMapAttribution}
            url="https://storage.googleapis.com/glasgow-prototype/{z}/{x}/{y}.png"
          />
          :
          <TileLayer
            attribution={osMapAttribution}
            url="https://europe-west2-glasgowbeforethemotorway.cloudfunctions.net/glasgow-tile-layer/{z}/{x}/{y}.png"
          />
          // <TileLayer
          //   attribution= '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          //   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          // />
        }

        {/*<LayersControl position="topright">*/}
        {/*  <LayersControl.BaseLayer name="Glasgow 1933/4" checked>*/}
        {/*    <TileLayer*/}
        {/*      attribution='Glasgow map derived from OS 25-inch maps reproduced with permission of the <a href="https://maps.nls.uk/index.html">National Library of Scotland</a> under CC-BY licence. Base map &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'*/}
        {/*      url="https://storage.googleapis.com/glasgow-prototype/{z}/{x}/{y}.png"*/}
        {/*    />*/}
        {/*  </LayersControl.BaseLayer>*/}
        {/*  <LayersControl.BaseLayer name="Base map (OpenStreetMap)">*/}
        {/*    <TileLayer*/}
        {/*      attribution= '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'*/}
        {/*      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"*/}
        {/*    />*/}
        {/*  </LayersControl.BaseLayer>*/}
        {/*  <LayersControl.Overlay name="Interesting features" checked>*/}
        {/*    {*/}
        {/*      markers.map(marker => (*/}
        {/*        <Marker key={marker.title} position={marker.position}>*/}
        {/*          <Popup>*/}
        {/*            <h3>{marker.title}</h3>*/}
        {/*            <p>{marker.description}</p>*/}
        {/*          </Popup>*/}
        {/*        </Marker>*/}
        {/*      ))*/}
        {/*    }*/}
        {/*  </LayersControl.Overlay>*/}
        {/*</LayersControl>*/}
      </MapContainer>
    </>
  )
}

export default App
